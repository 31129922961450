import {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react'
import { CssBaseline, ThemeProvider, useMediaQuery } from '@material-ui/core'

import { darkTheme, lightTheme } from '../theme'

const AppContext = createContext()

export const AppProvider = (props) => {
  const { children } = props
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState('system')
  const theme = useMemo(
    () => {
      if (mode === 'dark') {
        return darkTheme
      }
      if (mode === 'light') {
        return lightTheme
      }
      return (prefersDarkMode ? darkTheme : lightTheme)
    },
    [mode, prefersDarkMode],
  )
  const setTheme = useCallback(
    (m) => {
      setMode(m)
    },
    [],
  )
  return (
    <AppContext.Provider value={{ mode, setTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export const useApp = () => useContext(AppContext)

export default AppContext
