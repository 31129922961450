import { Divider, Paper, Typography } from '@material-ui/core'
import {
  Timeline, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator,
} from '@material-ui/lab'
import { School as SchoolIcon } from '@material-ui/icons'

const Education = () => (
  <Paper sx={{ p: 2 }}>
    <Typography variant="h5">
      Education
    </Typography>
    <Divider sx={{ my: 2 }} />
    <Timeline position="left" sx={{ py: 0, m: 0 }}>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
        >
          <Typography variant="h6" component="span">
            King Mongkut&rsquo;s Institute of Technology Ladkrabang
          </Typography>
          <Typography color="textSecondary">
            Bachelor of Science (BS), Information Technology
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <SchoolIcon />
          </TimelineDot>
        </TimelineSeparator>
      </TimelineItem>
    </Timeline>
  </Paper>
)

export default Education
