import { Divider, Paper, Typography } from '@material-ui/core'
import {
  Timeline, TimelineConnector, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator,
} from '@material-ui/lab'
import {
  CenterFocusWeak as CenterFocusWeakIcon, FlightTakeoff as FlightTakeoffIcon, LocalAtm as LocalAtmIcon, ScreenShare as ScreenShareIcon, VerifiedUser as VerifiedUserIcon, Web as WebIcon,
} from '@material-ui/icons'

const Project = () => (
  <Paper sx={{ p: 2 }}>
    <Typography variant="h5">
      Project
    </Typography>
    <Divider sx={{ my: 2 }} />
    <Timeline position="left" sx={{ py: 0, m: 0 }}>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
        >
          <Typography variant="h6" component="span">
            Currency exchange ERP
          </Typography>
          <Typography color="textSecondary">
            React, Next.js, GraphQL, MongoDB
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <LocalAtmIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
        >
          <Typography variant="h6" component="span">
            Freight forwarder ERP
          </Typography>
          <Typography color="textSecondary">
            React, Next.js, GraphQL, MongoDB
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <FlightTakeoffIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
        >
          <Typography variant="h6" component="span">
            Peer to Peer screen sharing
          </Typography>
          <Typography color="textSecondary">
            React, PeerJS
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <ScreenShareIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
        >
          <Typography variant="h6" component="span">
            Face detection and Face recognition system
          </Typography>
          <Typography color="textSecondary">
            React, GraphQL, TensorFlow.js, MongoDB, PostgreSQL
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <CenterFocusWeakIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
        >
          <Typography variant="h6" component="span">
            GSP 5.06 TH License system
          </Typography>
          <Typography color="textSecondary">
            AngularJS, MySQL
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <VerifiedUserIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
        >
          <Typography variant="h6" component="span">
            Shop landing page
          </Typography>
          <Typography color="textSecondary">
            Wordpress
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <WebIcon />
          </TimelineDot>
        </TimelineSeparator>
      </TimelineItem>
    </Timeline>
  </Paper>
)

export default Project
