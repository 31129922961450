import {
  Avatar, Box, Divider, Paper, Typography,
} from '@material-ui/core'

const BasicInfo = () => (
  <Paper sx={{ p: 2 }}>
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
    >
      <Avatar alt="Saksit Sawang" src="/images/iinitz2.jpg" sx={{ width: 120, height: 120, mb: 1 }} />
      <Typography variant="h4">
        Saksit Sawang
      </Typography>
      <Typography variant="h5" color="textSecondary">
        Developer Team Lead
      </Typography>
    </Box>
    <Divider sx={{ my: 2 }} />
    <Box sx={{ textAlign: 'center' }}>
      <Typography color="textSecondary">
        Just coding ...
      </Typography>
    </Box>
  </Paper>
)

export default BasicInfo
