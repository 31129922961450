import { createTheme, darkScrollbar } from '@material-ui/core'
import { blue, pink } from '@material-ui/core/colors'

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: blue,
    secondary: pink,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar(),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        '@media print': {
          display: 'none',
        },
      },
    },
  },
})
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: blue,
    secondary: pink,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar(),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        '@media print': {
          display: 'none',
        },
      },
    },
  },
})
