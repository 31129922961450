import {
  Box, Container, Grid, SpeedDial, SpeedDialAction, SpeedDialIcon,
} from '@material-ui/core'
import {
  Brightness4 as Brightness4Icon, Brightness7 as Brightness7Icon, Close as CloseIcon, Settings as SettingsIcon, SettingsBrightness as SettingsBrightnessIcon,
} from '@material-ui/icons'

import { useApp } from './contexts/AppContext'
import BasicInfo from './components/BasicInfo'
import Contact from './components/Contact'
import Education from './components/Education'
import Experience from './components/Experience'
import Project from './components/Project'
import Skill from './components/Skill'

const App = () => {
  const { setTheme } = useApp()
  return (
    <Box sx={{ py: 2, bgcolor: 'divider' }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 2 }}>
              <BasicInfo />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Contact />
            </Box>
            <Box>
              <Skill />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ mb: 2 }}>
              <Education />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Experience />
            </Box>
            <Box>
              <Project />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <SpeedDial
        sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          '@media print': {
            display: 'none',
          },
        }}
        color="primary"
        ariaLabel="Setting"
        icon={<SpeedDialIcon icon={<SettingsIcon />} openIcon={<CloseIcon />} />}
      >
        <SpeedDialAction icon={<Brightness4Icon />} tooltipTitle="Dark theme" onClick={() => setTheme('dark')} />
        <SpeedDialAction icon={<SettingsBrightnessIcon />} tooltipTitle="System theme" onClick={() => setTheme('system')} />
        <SpeedDialAction icon={<Brightness7Icon />} tooltipTitle="Light theme" onClick={() => setTheme('light')} />
        {/* <SpeedDialAction icon={<PrintIcon />} tooltipTitle="Print" onClick={() => window.print()} /> */}
      </SpeedDial>
    </Box>
  )
}

export default App
