import {
  Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography,
} from '@material-ui/core'
import { Email as EmailIcon, GitHub as GitHubIcon, Phone as PhoneIcon } from '@material-ui/icons'

const Contact = () => (
  <Paper sx={{ p: 2 }}>
    <Typography variant="h5">
      Contact
    </Typography>
    <Divider sx={{ my: 2 }} />
    <List dense sx={{ p: 0 }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
            <PhoneIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="+66 81 380 7789" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
            <EmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="saksit@nativesrc.com" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
            <GitHubIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="github.com/iinitz" />
      </ListItem>
    </List>
  </Paper>
)

export default Contact
